<template>
  <MarketItems />
</template>

<script>
import MarketItems from "@/components/Tables/MarketItems.vue";

export default {
  components: { MarketItems },
};
</script>
